@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(235, 235, 235);
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}

